.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  /* Toggle Switch Styles */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}
  
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #2196F3;
}
  
input:checked + .slider:before {
    transform: translateX(26px);
}
  
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

/* Carousel Animation */
@keyframes carousel {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-400px * 9));
    }
}

.animate-carousel {
    animation: carousel 60s linear infinite;
    will-change: transform;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.animate-carousel:hover {
    animation-play-state: paused;
}

/* Add these new styles */
.carousel-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 20px 0;
}

.carousel-track {
    display: flex;
    gap: 2rem; /* 8px, matching your existing gap-8 */
    width: fit-content;
}